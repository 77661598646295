import React, { useState, useEffect } from 'react';
import Sidebar from '../Components/SideBar';
import ImageSlider from '../Components/ImageSlider';

const Bookings = () => {
    const [bookings, setBookings] = useState([]);

    const handleDelete = async(id) => {
        const confirmDelete = window.confirm('Вы действительно хотите удалить?');
        if (confirmDelete) {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const user = JSON.parse(storedUser);
                try {
                    const response = await fetch(`https://adm.trevatour.ru/api/app/deletebooking.php?login=${user.login}&hash=${user.hash}&booking_id=${id}`);
                    const data = await response.json();
        
                    if (response.ok) {
                        if(data.bookings){
                            const bookingsWithModifications = data.bookings.map(item => {
                                return {
                                    ...item,
                                    booking: {
                                        ...item.booking,
                                        images: item.booking.images.split(','),
                                        created_at: new Date(item.booking.created_at).toLocaleDateString('ru-RU', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })
                                    }
                                };
                            });
                            setBookings(bookingsWithModifications);
                        }else {
                            alert(data.error)
                        }
                    } else {
                        throw new Error(data.message || 'Login failed');
                    }
                } catch (error) {
                    alert(`Error: ${error.message}`);
                }
            }
        } else {
            console.log('Delete cancelled.');
        }
    };
    
    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await fetch(`https://adm.trevatour.ru/api/app/getbookings.php`);

                const data = await response.json();

                if (response.ok) {
                    if(data.bookings){
                        const bookingsWithModifications = data.bookings.map(item => {
                            return {
                                ...item,
                                booking: {
                                    ...item.booking,
                                    images: item.booking.images.split(','),
                                    created_at: new Date(item.booking.created_at).toLocaleDateString('ru-RU', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })
                                }
                            };
                        });
                        setBookings(bookingsWithModifications);
                    }else {
                        alert(data.error)
                    }
                } else {
                    throw new Error(data.message || 'Login failed');
                }
            } catch (error) {
                alert(`Error: ${error.message}`);
            }
        };

        fetchBookings();
    }, []);


    return (
        <div>
            <div style={{ padding: '20px', flex: 1, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <h1 style={{marginTop: 50, display: 'flex', gap: '20px'}}>
                    Ночлег
                    <a href={`/bookings/add`} style={{padding: 0, margin: 0, textDecoration: 'none'}}>
                    <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#4A6118"/>
                        <path d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z" fill="white"/>
                    </svg>
                    </a>
                </h1>
                {bookings.map((booking, index) => (
                    <>
                    <div style={{width: '100%', display: 'flex', gap: '10px'}} key={index}>
                        <ImageSlider images={booking.booking.images}/>
                        <div>
                            <h3>{booking.booking.title}</h3>
                            <p>{booking.booking.description}</p>
                            <a onClick={() => handleDelete(booking.booking.id)} style={{color: 'darkred', cursor: 'pointer', textDecorationLine: 'underline'}}>Удалить</a>
                            <a href={`/bookings/${booking.booking.id}/change`} style={{marginLeft: '20px', color: 'black'}}>Изменить</a>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{maxWidth: '300px'}}>
                            <p><b>Категория: </b>{booking.booking.category}</p>
                            <p><b>Рейтинг: </b>{booking.reviews.length > 0 ? (booking.reviews.reduce((sum, review) => sum + parseInt(review.rate), 0)/booking.reviews.length).toFixed(1) : 0}</p>
                            <p><b>Цена: </b>{booking.booking.price}</p>
                            <p><b>Единица: </b>{booking.booking.point}</p>
                            <p><b>latitude: </b>{booking.booking.latitude}</p>
                            <p><b>longitude: </b>{booking.booking.longitude}</p>
                            <p>📅 {booking.booking.created_at}</p>
                            <p>📧 {booking.booking.email}</p>
                            <p>📱 {booking.booking.phone}</p>
                            <p>🌍 {booking.booking.website}</p>
                            <p>❤️ {booking.booking.likes_count}</p>
                            <p>💬 {booking.reviews.length}</p>
                        </div>
                        <div style={{width: '100%', maxHeight: '420px', overflowY: 'scroll', margin: '10px 0px', backgroundColor: 'rgb(250, 250, 250)', scrollbarWidth: 'thin'}}>
                            {
                                booking.reviews.map((review, index) => (
                                    <div style={{width: '100%', padding: '10px 0', borderBottom: '1px solid white', display: 'flex', gap: '10px'}}>
                                        <a>
                                            <img src={review.image} style={{width: '40px', height: '40px', borderRadius: '20px', marginLeft: '10px'}} />  
                                        </a>
                                        <div>
                                            <p style={{padding: 0, margin: 0, fontWeight: '600'}}>{review.full_name}</p>
                                            <p style={{padding: 0, margin: 0}}>{review.rate} балл</p>
                                            <p style={{padding: 0, margin: 0}}>{review.review}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>
                    
                ))}
            </div>
        </div>
    );
};

export default Bookings;
