import React, { useState, useEffect } from 'react';
import RoutesSlider from '../Components/RoutesSlider';

const RoutesScreen = () => {
    const [routes, setRoutes] = useState([]);
    
    useEffect(() => {
        const fetchRoutes = async () => {
            try {
                const response = await fetch(`https://adm.trevatour.ru/api/app/getallroutes.php`);

                const data = await response.json();

                if (response.ok) {
                    if(data.routes){
                        const routesWithModifications = data.routes.map(item => {
                            const formattedDate = new Date(item.created_at).toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });
                            return {
                                ...item,
                                created_at: formattedDate
                            };
                        });
                        setRoutes(routesWithModifications);
                    }else {
                        alert(data.error)
                    }
                } else {
                    throw new Error(data.message || 'Login failed');
                }
            } catch (error) {
                alert(`Error: ${error.message}`);
            }
        };

        fetchRoutes();
    }, []);

    const handleDelete = async(id) => {
        const confirmDelete = window.confirm('Вы действительно хотите удалить?');
        if (confirmDelete) {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const user = JSON.parse(storedUser);
                try {
                    const response = await fetch(`https://adm.trevatour.ru/api/app/deleterouteadmin.php?login=${user.login}&hash=${user.hash}&route_id=${id}`);
                    const data = await response.json();
        
                    if (response.ok) {
                        if(data.routes){
                            const routesWithModifications = data.routes.map(item => {
                                const formattedDate = new Date(item.created_at).toLocaleDateString('ru-RU', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                });
                                return {
                                    ...item,
                                    created_at: formattedDate
                                };
                            });
                            setRoutes(routesWithModifications);
                        }else {
                            alert(data.error)
                        }
                    } else {
                        throw new Error(data.message || 'Login failed');
                    }
                } catch (error) {
                    alert(`Error: ${error.message}`);
                }
            }
        } else {
            console.log('Delete cancelled.');
        }
    };

    return (
        <div>
            <div style={{ padding: '20px', flex: 1, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <h1  style={{marginTop: 50}}>Маршруты</h1>
                {routes.map((route, index) => (
                    <div style={{width: '100%', display: 'flex', gap: '10px'}} key={index}>
                        <RoutesSlider places={route.places}/>
                        <div>
                            <h3>{route.title}</h3>
                            <div style={{width: '100%', display: 'flex', gap: 10}}>
                                <img style={{width: '50px', height: '50px', borderRadius: '25px'}} src={route.image}/>
                                <p style={{fontWeight: '600'}}>{route.user_full_name} {route.user_id}</p>
                            </div>
                            <p>📅 {route.created_at}</p>
                            <p>❤️ {route.likes_count}</p>
                            <a onClick={() => handleDelete(route.id)} style={{color: 'darkred', cursor: 'pointer', textDecorationLine: 'underline'}}>Удалить</a>
                            <p>{route.description}</p>
                        </div>
                    </div>
                    
                ))}
            </div>
        </div>
    );
};

export default RoutesScreen;
