import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddTrip = () => {
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [maxCount, setMaxCount] = useState(0);
  const [count, setCount] = useState(0);
  const [type, setType] = useState('');
  const [schedule, setSchedule] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [price, setPrice] = useState(0);
  const [team, setTeam] = useState('');
  const [link, setLink] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
  
      if (description.length === 0 || !image) {
        alert('Please fill out all required fields.');
        return;
      }
  
      const formData = new FormData();
      formData.append('login', user.login);
      formData.append('hash', user.hash);
      formData.append('title', title);
      formData.append('short_description', shortDescription);
      formData.append('description', description);
      formData.append('max_count', maxCount);
      formData.append('count', count);
      formData.append('type', type);
      formData.append('schelude', schedule);
      formData.append('difficulty', difficulty);
      formData.append('price', price);
      formData.append('team', team);
      formData.append('link', link);
      formData.append('start_date', startDate);
      formData.append('end_date', endDate);
      if (image) formData.append('image', image);
  
      try {
        const response = await fetch('https://adm.trevatour.ru/api/website/addtrip.php', {
          method: 'POST',
          body: formData,
        });
  
        const text = await response.text(); // Read response as text to handle non-JSON responses
        console.log('Response text:', text);
  
        try {
          const data = JSON.parse(text); // Attempt to parse JSON
          if (data.success) {
            navigate('/website/trips');
          } else {
            alert(data.error || 'Failed to add trip.');
          }
        } catch (jsonError) {
          console.error('Failed to parse JSON:', jsonError);
          alert('Unexpected server response.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while adding the trip.');
      }
    } else {
      alert('User not found.');
    }
  };
  

  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ margin: 0, paddingTop: '50px' }}>Добавить в команду</h1>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
      <label style={{ marginTop: '10px' }}>
        Заголовок:
        <br />
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Короткое описание:
        <br />
        <input
          type="text"
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Описание:
        <br />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Максимальное количество:
        <br />
        <input
          type="number"
          value={maxCount}
          onChange={(e) => setMaxCount(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Количество:
        <br />
        <input
          type="number"
          value={count}
          onChange={(e) => setCount(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Тип тура
        <br />
        <input
          type="text"
          value={type}
          onChange={(e) => setType(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Расписание #:
        <br />
        <textarea
          type="text"
          value={schedule}
          onChange={(e) => setSchedule(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Сложность:
        <br />
        <input
          type="text"
          value={difficulty}
          onChange={(e) => setDifficulty(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Цена:
        <br />
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Команда #:
        <br />
        <input
          type="text"
          value={team}
          onChange={(e) => setTeam(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Ссылка:
        <br />
        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Начало:
        <br />
        <input
          type="datetime-local"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
      <label style={{ marginTop: '10px' }}>
        Конец:
        <br />
        <input
          type="datetime-local"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </label>
        <label style={{ marginTop: '10px' }}>
          Фотография:
          <br />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {image != null && <div style={{ position: 'relative', marginRight: '10px', marginBottom: '10px' }}>
              <img
                src={URL.createObjectURL(image)}
                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '4px', marginRight: '5px' }}
              />
            </div>}
          </div>
        <br/>
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          />
        </label>
        <button
          type="submit"
          style={{
            marginTop: '20px',
            padding: '10px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#4A6118',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddTrip;
