import React, { useState } from 'react';
import './ImageSlider.css'; // We'll add some CSS for styling

const RoutesSlider = ({ places }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? places.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === places.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="slider-container">
      <button className="left-arrow" onClick={goToPrevious}>
        &lt;
      </button>
      <div className="slider">
        <img style={{width: '400px', height: '400px', borderRadius: '24px', objectFit: 'cover'}} src={places[currentIndex].image}/>
        <div style={{ textDecoration: 'none', textDecorationLine: 'none', width: '100%', position: 'absolute', bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '0px 0px 24px 24px' }}>
          <h2 style={{color: 'white'}}>{places[currentIndex].title}</h2>
          <p style={{color: 'white', padding: 0, margin: 0}}><b>latitude: </b>{places[currentIndex].latitude}</p>
          <p style={{color: 'white'}}><b>longitude: </b>{places[currentIndex].longitude}</p>
        </div>
      </div>
      <button className="right-arrow" onClick={goToNext}>
        &gt;
      </button>
    </div>
  );
};

export default RoutesSlider;
