import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const ChangeReview = () => {
  const [description, setDescription] = useState('');
  const {id} = useParams()
  const navigate = useNavigate()

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        e.preventDefault();
        
        // Проверка, что все поля заполнены
        if (description.length === 0) {
            alert('Заполните все поля');
            return;
        }

        const user = JSON.parse(storedUser);

        const formData = new FormData();
        formData.append('login', user.login);
        formData.append('hash', user.hash);
        formData.append('id', id);
        formData.append('description', description);

        try {
            const response = await fetch('https://adm.trevatour.ru/api/website/changereview.php', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to add news');
            }

            const data = await response.json();
            if (data.success) {
                navigate('/website/reviews');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    } else {
        alert('Пользователь не найден');
    }
  };

    useEffect(() => {
        const fetchNew = async () => {
            try {
                const response = await fetch(`https://adm.trevatour.ru/api/website/getreviews.php`);

                const data = await response.json();

                if (response.ok) {
                    if(!data.message){
                        const item = data.find(item => item.id === id);
                        if (item) {
                            setDescription(item.description);
                        } else {
                            console.error('Item with the specified ID not found.');
                        }
                    }else {
                        alert(data.message)
                    }
                } else {
                    throw new Error(data.message || 'Login failed');
                }
            } catch (error) {
                alert(`Error: ${error.message}`);
            }
        };

        fetchNew();
    }, []);

  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ margin: 0, paddingTop: '50px' }}>Изменить отзыв</h1>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginTop: '10px' }}>
          Описание:
          <br />
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <button
          type="submit"
          style={{
            marginTop: '20px',
            padding: '10px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#4A6118',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ChangeReview;
