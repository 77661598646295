import React, { useState, useEffect } from 'react';

const Users = () => {
    const [users, setUsers] = useState([]);

    const handleBlock = async(id) => {
        const confirmDelete = window.confirm('Вы действительно хотите завершить действие?');
        if (confirmDelete) {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const user = JSON.parse(storedUser);
                try {
                    const response = await fetch(`https://adm.trevatour.ru/api/app/blockuser.php?login=${user.login}&hash=${user.hash}&user_id=${id}`);
                    const data = await response.json();
        
                    if (response.ok) {
                        if(data.users){
                            setUsers(data.users);
                        }else {
                            alert(data.error)
                        }
                    } else {
                        throw new Error(data.message || 'Login failed');
                    }
                } catch (error) {
                    alert(`Error: ${error.message}`);
                }
            }
        } else {
            console.log('Delete cancelled.');
        }
    };
    
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`https://adm.trevatour.ru/api/app/getusers.php`);

                const data = await response.json();

                if (response.ok) {
                    if(data.users){
                        setUsers(data.users);
                    }else {
                        alert(data.error)
                    }
                } else {
                    throw new Error(data.message || 'Login failed');
                }
            } catch (error) {
                alert(`Error: ${error.message}`);
            }
        };

        fetchUsers();
    }, []);


    return (
        <div>
            <div style={{padding: '20px'}}>
            <h1 style={{width: '100%', marginTop: '50px'}}>Пользователи</h1>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap'}}>
                {users.map((user, index) => (
                    <div style={{display: 'flex', gap: '10px'}} key={index}>
                        <div style={{ width: '400px', height: '300px', borderRadius: '24px', backgroundImage: `url(${user.background_image})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative' }}>
                            <div style={{ textDecoration: 'none', textDecorationLine: 'none', width: '100%', position: 'absolute', bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '0px 0px 24px 24px' }}>
                                <img style={{width: '100px', height: '100px', marginTop: '-50px', borderRadius: '50px'}} src={user.image}/>
                                <h2 style={{color: 'white', margin: 0, padding: 0}}>{user.full_name} {user.id}</h2>
                                <p style={{color: 'white', margin: 0, padding: 0}}>{user.place_of_residence}</p>
                                <p style={{color: 'white', margin: 0, padding: 0}}>{user.email}</p>
                                <p style={{color: 'white', margin: 0, padding: 0}}>❤️ {user.likes_count}</p>
                                <p style={{color: 'white', margin: 0, padding: 0}}>{user.phone}</p>
                                <a onClick={() => handleBlock(user.id)} style={{cursor: 'pointer', color: user.status == 'blocked' ? 'white' :'red', textDecorationLine: 'underline', margin: '10px', fontWeight: '600'}}>{user.status == 'blocked' ? 'Разблокировать' : 'Заблокировать'}</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            </div>
        </div>
    );
};

export default Users;
