import React, { useState, useEffect } from 'react';

const Appeals = () => {
    const [appeals, setAppeals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAppeals = async () => {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const user = JSON.parse(storedUser);
                const formData = new FormData();
                formData.append('login', user.login);
                formData.append('hash', user.hash);

                try {
                    const response = await fetch('https://adm.trevatour.ru/api/app/getallappeals.php', {
                        method: 'POST',
                        body: formData,
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    try {
                        const data = await response.json();
                        console.log('heey', data)
                        if (data.appeals) {
                            const appealsWithModifications = data.appeals.map(item => {
                                const formattedDate = new Date(item.created_at).toLocaleDateString('ru-RU', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                });
                                return {
                                    ...item,
                                    created_at: formattedDate
                                };
                            });
                            setAppeals(appealsWithModifications);
                        } else {
                            setError(data.error || 'Unknown error occurred');
                        }
                    } catch (jsonError) {
                        throw new Error('Failed to parse JSON response');
                    }
                } catch (error) {
                    setError(`Error: ${error.message}`);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchAppeals();
    }, []); // Ensure dependencies are set correctly

    return (
        <div style={{ padding: '20px', flex: 1, display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <h1 style={{ marginTop: 50 }}>
                Жалобы ({appeals.length})
            </h1>
            {loading ? (
                <div>Загрузка...</div>
            ) : error ? (
                <div className="error">{error}</div>
            ) : appeals.length === 0 ? (
                <div>Нет жалоб.</div>
            ) : (
                <table border="1" cellPadding="10" style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>From</th>
                            <th>Appeal</th>
                            <th>To</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appeals.map(appeal => (
                            <tr key={appeal.appeal_id}>
                                <td>{appeal.appeal_id}</td>
                                <td style={{ textAlign: 'center', padding: '10px' }}>
                                        <div style={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                                            <img src={appeal.from_image} alt="From" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                            <span>{appeal.from_name}</span>
                                        </div>
                                </td>
                                <td>{appeal.appeal}</td>
                                <td style={{ textAlign: 'center', padding: '10px' }}>
                                    <div style={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                                        <img src={appeal.to_image} alt="To" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                        <span>{appeal.to_name}</span>
                                    </div>
                                </td>
                                <td>
                                    <span role="img" aria-label="calendar">📅</span> {appeal.created_at}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Appeals;
