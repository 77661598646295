import React, { useState } from 'react';
import './ImageSlider.css'; // We'll add some CSS for styling

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="slider-container">
      <button className="left-arrow" onClick={goToPrevious}>
        &lt;
      </button>
      <div className="slider">
      <img style={{width: '400px', height: '400px', borderRadius: '24px', objectFit: 'cover'}} src={images[currentIndex]}/>
      </div>
      <button className="right-arrow" onClick={goToNext}>
        &gt;
      </button>
    </div>
  );
};

export default ImageSlider;
