// Sidebar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = ({ children }) => {
    const location = useLocation();

    return (
        <div className="topbar">
            <ul className="topbar-nav">
                <li className={(location.pathname === '/' || location.pathname.includes('news')) ? 'active' : ''}>
                    <Link to="/">Новости</Link>
                </li>
                <li className={location.pathname.includes('/categories') ? 'active' : ''}>
                    <Link to="/categories">Категории</Link>
                </li>
                <li className={location.pathname.includes('/bookings') ? 'active' : ''}>
                    <Link to="/bookings">Ночлег</Link>
                </li>
                <li className={location.pathname.includes('/store') ? 'active' : ''}>
                    <Link to="/store">Товары</Link>
                </li>
                <li className={location.pathname.includes('ru/rental') ? 'active' : ''}>
                    <Link to="/rental">Аренда</Link>
                </li>
                <li className={location.pathname === '/routes' ? 'active' : ''}>
                    <Link to="/routes">Маршруты</Link>
                </li>
                <li className={location.pathname === '/users' ? 'active' : ''}>
                    <Link to="/users">Пользователи</Link>
                </li>
                <li className={location.pathname.includes('/orders') ? 'active' : ''}>
                    <Link to="/orders">Заказы</Link>
                </li>
                <li className={location.pathname.includes('/appeals') ? 'active' : ''}>
                    <Link to="/appeals">Жалобы</Link>
                </li>


                <div style={{width: 1, height: 35, backgroundColor: 'lightgray'}}></div>

                <li className={location.pathname.includes('/website/reviews') ? 'active' : ''}>
                    <Link to="/website/reviews">Отзывы</Link>
                </li>
                <li className={location.pathname.includes('/website/faq') ? 'active' : ''}>
                    <Link to="/website/faq">FAQ</Link>
                </li>
                <li className={location.pathname.includes('/website/wheretogo') ? 'active' : ''}>
                    <Link to="/website/wheretogo">Куда съездить?</Link>
                </li>
                <li className={location.pathname.includes('/website/blog') ? 'active' : ''}>
                    <Link to="/website/blog">Блог</Link>
                </li>
                <li className={location.pathname.includes('/website/team') ? 'active' : ''}>
                    <Link to="/website/team">Команда</Link>
                </li>
                <li className={location.pathname.includes('/website/rental') ? 'active' : ''}>
                    <Link to="/website/rental">Прокат</Link>
                </li>
                <li className={location.pathname.includes('/website/trips') ? 'active' : ''}>
                    <Link to="/website/trips">Поездки</Link>
                </li>
            </ul>
            <div className="topbar-content">
                {children}
            </div>
        </div>
    );
};

export default Sidebar;
