import React, { useState, useEffect } from 'react';
import Sidebar from '../Components/SideBar';

const Categories = () => {
    const [categories, setCategories] = useState([]);

    const handleDelete = async(id) => {
        const confirmDelete = window.confirm('Вы действительно хотите удалить?');
        if (confirmDelete) {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const user = JSON.parse(storedUser);
                try {
                    const response = await fetch(`https://adm.trevatour.ru/api/app/deletecategory.php?login=${user.login}&hash=${user.hash}&category_id=${id}`);
                    const data = await response.json();
        
                    if (response.ok) {
                        if(data.categories){
                            setCategories(data.categories);
                        }else {
                            alert(data.error)
                        }
                    } else {
                        throw new Error(data.message || 'Login failed');
                    }
                } catch (error) {
                    alert(`Error: ${error.message}`);
                }
            }
        } else {
            console.log('Delete cancelled.');
        }
    };
    
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`https://adm.trevatour.ru/api/app/getplacecategories.php`);

                const data = await response.json();

                if (response.ok) {
                    if(data.place_categories){
                        setCategories(data.place_categories);
                    }else {
                        alert(data.error)
                    }
                } else {
                    throw new Error(data.message || 'Login failed');
                }
            } catch (error) {
                alert(`Error: ${error.message}`);
            }
        };

        fetchCategories();
    }, []);


    return (
        <div>
            <div style={{padding: '20px'}}>
            <h1 style={{marginTop: 50, display: 'flex', gap: '20px'}}>
                Категории
                <a href='/categories/add' style={{padding: 0, margin: 0, textDecoration: 'none'}}>
                <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#4A6118"/>
                    <path d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z" fill="white"/>
                </svg>
                </a>
            </h1>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap'}}>
                {categories.map((category, index) => (
                    <div style={{display: 'flex', gap: '10px'}} key={index}>
                        <div style={{ width: '400px', height: '400px', borderRadius: '24px', backgroundImage: `url(${category.image})`, backgroundSize: 'cover', backgroundPosition: 'center', position: 'relative' }}>
                            <div style={{ width: '100%', position: 'absolute', display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '10px 0px' }}>
                                <a onClick={() => handleDelete(category.id)} style={{cursor: 'pointer', color: 'red', textDecorationLine: 'underline'}}>Удалить</a>
                                <a href={`/categories/${category.id}/change`} style={{cursor: 'pointer', color: 'white', textDecorationLine: 'underline'}}>Изменить</a>
                            </div>
                            <a href={`/categories/${category.id}/${category.title}`} style={{ textDecoration: 'none', textDecorationLine: 'none', width: '100%', cursor: 'pointer', position: 'absolute', bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0px 0px 24px 24px' }}>
                                <h2 style={{color: 'white'}}>{category.title + ' ' + category.id}</h2>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            </div>
        </div>
    );
};

export default Categories;
