// App.js
import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Pages/Login';
import News from './Pages/News';
import Categories from './Pages/Categories';
import Category from './Pages/Category';
import Bookings from './Pages/Bookings';
import Store from './Pages/Store';
import Sidebar from './Components/SideBar';
import Rental from './Pages/Rental';
import Users from './Pages/Users';
import RoutesScreen from './Pages/Routes';
import AddNews from './Pages/AddNews';
import AddCategory from './Pages/AddCategory';
import AddPlace from './Pages/AddPlace';
import AddBooking from './Pages/AddBooking';
import AddStore from './Pages/AddStore';
import AddRental from './Pages/AddRental';
import ChangeNews from './Pages/ChangeNews';
import ChangeCategory from './Pages/ChangeCategory';
import ChangePlace from './Pages/ChangePlace';
import ChangeBooking from './Pages/ChangeBooking';
import ChangeStore from './Pages/ChangeStore';
import ChangeRental from './Pages/ChangeRental';
import Orders from './Pages/Orders';
import Appeals from './Pages/Appeals';
import Reviews from './Pages/Website/Reviews';
import AddReview from './Pages/Website/AddReview';
import ChangeReview from './Pages/Website/ChangeReview';
import Faq from './Pages/Website/Faq';
import AddFaq from './Pages/Website/AddFaq';
import ChangeFaq from './Pages/Website/ChangeFaq';
import WhereToGo from './Pages/Website/WhereToGo';
import ChangeWhereToGo from './Pages/Website/ChangeWhereToGo';
import AddWhereToGo from './Pages/Website/AddWhereToGo';
import Blog from './Pages/Website/Blog';
import AddBlog from './Pages/Website/AddBlog';
import ChangeBlog from './Pages/Website/ChangeBlog';
import Team from './Pages/Website/Team';
import AddTeam from './Pages/Website/AddTeam';
import ChangeTeam from './Pages/Website/ChangeTeam';
import RentalWeb from './Pages/Website/RentalWeb';
import AddRentalWeb from './Pages/Website/AddRentalWeb';
import ChangeRentalWeb from './Pages/Website/ChangeRentalWeb';
import Trips from './Pages/Website/Trips';
import AddTrip from './Pages/Website/AddTrip';
import ChangeTrip from './Pages/Website/ChangeTrip';

function App() {

    const [isAuthenticated, setIsAuthenticated] = useState(false)

    useEffect(() => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
          const user = JSON.parse(storedUser);
          verifyUser(user);
      }
    }, []);

    const verifyUser = async (user) => {
      try {
        const response = await fetch(`https://adm.trevatour.ru/api/app/getsuperuser.php?login=${user.login}&hash=${user.hash}`);
        const data = await response.json();

        if (response.ok) {
          if(data.user){
            localStorage.setItem('user', JSON.stringify(data.user));
            setIsAuthenticated(true)
          }else {
            alert(data.error)
            localStorage.clear()
          }
        } else {
          localStorage.clear()
          throw new Error(data.message || 'Login failed');
        }
      } catch (error) {
        localStorage.removeItem('user'); 
      }
    };
    return (
            <Router>
                {isAuthenticated && <Sidebar />}
                <Routes>
                    {
                      isAuthenticated ?
                      <>
                      <Route path="/" element={<News />} />
                      <Route path="/news/add" element={<AddNews />} />
                      <Route path="/news/:id/change" element={<ChangeNews />} />
                      <Route path="/categories" element={<Categories />} />
                      <Route path="/categories/add" element={<AddCategory />} />
                      <Route path="/categories/:id/change" element={<ChangeCategory />} />
                      <Route path="/categories/:category/:title" element={<Category />} />
                      <Route path="/categories/:category/add" element={<AddPlace />} />
                      <Route path="/categories/:category/:id/change" element={<ChangePlace />} />
                      <Route path="/bookings" element={<Bookings />} />
                      <Route path="/bookings/add" element={<AddBooking />} />
                      <Route path="/bookings/:id/change" element={<ChangeBooking />} />
                      <Route path="/store" element={<Store />} />
                      <Route path="/store/add" element={<AddStore />} />
                      <Route path="/store/:id/change" element={<ChangeStore />} />
                      <Route path="/rental" element={<Rental />} />
                      <Route path="/rental/add" element={<AddRental />} />
                      <Route path="/rental/:id/change" element={<ChangeRental />} />
                      <Route path="/routes" element={<RoutesScreen />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/orders" element={<Orders />} />
                      <Route path="/appeals" element={<Appeals />} />

                      <Route path="/website/reviews" element={<Reviews />} />
                      <Route path="/website/reviews/add" element={<AddReview />} />
                      <Route path="/website/reviews/:id/change" element={<ChangeReview />} />
                      <Route path="/website/faq" element={<Faq />} />
                      <Route path="/website/faq/add" element={<AddFaq />} />
                      <Route path="/website/faq/:id/change" element={<ChangeFaq />} />
                      <Route path="/website/wheretogo" element={<WhereToGo />} />
                      <Route path="/website/wheretogo/:id/change" element={<ChangeWhereToGo />} />
                      <Route path="/website/wheretogo/add" element={<AddWhereToGo />} />
                      <Route path="/website/blog" element={<Blog />} />
                      <Route path="/website/blog/add" element={<AddBlog />} />
                      <Route path="/website/blog/:id/change" element={<ChangeBlog />} />
                      <Route path="/website/team" element={<Team />} />
                      <Route path="/website/team/add" element={<AddTeam />} />
                      <Route path="/website/team/:id/change" element={<ChangeTeam />} />
                      <Route path="/website/rental" element={<RentalWeb />} />
                      <Route path="/website/rental/add" element={<AddRentalWeb />} />
                      <Route path="/website/rental/:id/change" element={<ChangeRentalWeb />} />
                      <Route path="/website/trips" element={<Trips />} />
                      <Route path="/website/trips/add" element={<AddTrip />} />
                      <Route path="/website/trips/:id/change" element={<ChangeTrip />} />


                      </>
                      :
                      <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                    }
                </Routes>
            </Router>
    );
}

export default App;
