import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ChangeTeam = () => {
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const {id} = useParams()

  const navigate = useNavigate()

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        const user = JSON.parse(storedUser);

        // Check that all fields are filled
        if (description.length === 0 || image == null) {
            alert('Заполните все поля');
            return;
        }

        const formData = new FormData();
        formData.append('login', user.login);
        formData.append('hash', user.hash);
        formData.append('id', id);
        formData.append('description', description);
        formData.append('image', image);
    
        try {
            const response = await fetch('https://adm.trevatour.ru/api/website/changeteam.php', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to add news');
            }

            const data = await response.json();
            if (data.success) {
                navigate('/website/team');
            } else {
                alert(data.error || 'Не удалось добавить категорию');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Произошла ошибка при добавлении категории');
        }
    } else {
        alert('Пользователь не найден');
    }
};

useEffect(() => {
    const fetchCategory = async () => {
        try {
            const response = await fetch(`https://adm.trevatour.ru/api/website/getteam.php`);

            const data = await response.json();

            if (response.ok) {
              if(!data.message){
                  const item = data.find(item => item.id === id);
                  if (item) {
                      setDescription(item.description);
                      setImage(item.image_url)
                  } else {
                      console.error('Item with the specified ID not found.');
                  }
              }else {
                  alert(data.message)
              }
          } else {
              throw new Error(data.message || 'Login failed');
          }
        } catch (error) {
            alert(`Error: ${error.message}`);
        }
    };

    fetchCategory();
}, []);


  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ margin: 0, paddingTop: '50px' }}>Изменить команду</h1>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginTop: '10px' }}>
          Описание:
          <br />
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Фотография:
          <br />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {image != null && <div style={{ position: 'relative', marginRight: '10px', marginBottom: '10px' }}>
              <img
                src={typeof image != 'string' ? URL.createObjectURL(image) : image}
                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '4px', marginRight: '5px' }}
              />
            </div>}
          </div>
        <br/>
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          />
        </label>
        <button
          type="submit"
          style={{
            marginTop: '20px',
            padding: '10px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#4A6118',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ChangeTeam;
