import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const ChangeFaq = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const {id} = useParams()
  const navigate = useNavigate()

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value);
  };

  const handleSubmit = async (e) => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        e.preventDefault();
        
        // Проверка, что все поля заполнены
        if (question.length === 0 || answer.length === 0) {
            alert('Заполните все поля');
            return;
        }

        const user = JSON.parse(storedUser);

        const formData = new FormData();
        formData.append('login', user.login);
        formData.append('hash', user.hash);
        formData.append('id', id);
        formData.append('question', question);
        formData.append('answer', answer);

        try {
            const response = await fetch('https://adm.trevatour.ru/api/website/changefaq.php', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to add news');
            }

            const data = await response.json();
            if (data.success) {
                navigate('/website/faq');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    } else {
        alert('Пользователь не найден');
    }
  };

    useEffect(() => {
        const fetchNew = async () => {
            try {
                const response = await fetch(`https://adm.trevatour.ru/api/website/getfaqlist.php`);

                const data = await response.json();

                if (response.ok) {
                    if(!data.message){
                        const item = data.find(item => item.id === id);
                        if (item) {
                            setQuestion(item.question);
                            setAnswer(item.answer)
                        } else {
                            console.error('Item with the specified ID not found.');
                        }
                    }else {
                        alert(data.message)
                    }
                } else {
                    throw new Error(data.message || 'Login failed');
                }
            } catch (error) {
                alert(`Error: ${error.message}`);
            }
        };

        fetchNew();
    }, []);

  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ margin: 0, paddingTop: '50px' }}>Изменить отзыв</h1>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginTop: '10px' }}>
          Вопрос:
          <br />
          <textarea
            value={question}
            onChange={handleQuestionChange}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Ответ:
          <br />
          <textarea
            value={answer}
            onChange={handleAnswerChange}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <button
          type="submit"
          style={{
            marginTop: '20px',
            padding: '10px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#4A6118',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>

        <p>
        {answer.split('\n').map((paragraph, index) => (
            <Fragment key={index}>
                <div dangerouslySetInnerHTML={{__html: paragraph}}></div>
            </Fragment>
        ))}
        </p>
      </form>
    </div>
  );
};

export default ChangeFaq;
