import React, { useState, useEffect } from 'react';
import ImageSlider from '../Components/ImageSlider';

const Orders = () => {
    const [orders, setStore] = useState([]);

    const handleChange = async (id) => {
        let new_status = prompt('Введите новый статус', 'Доставлен') || '';
        if (new_status.length > 0) {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const user = JSON.parse(storedUser);
                const formData = new FormData();
                formData.append('login', user.login);
                formData.append('hash', user.hash);
                formData.append('status', new_status);
                formData.append('order_id', id);
    
                try {
                    console.log('Sending data:', {
                        login: user.login,
                        hash: user.hash,
                        status: new_status,
                        order_id: id
                    });
    
                    const response = await fetch('https://adm.trevatour.ru/api/app/changeorderstatus.php', {
                        method: 'POST',
                        body: formData,
                    });
    
                    const data = await response.json();
    
                    if (response.ok) {
                        if (data.orders) {
                            const storeWithModifications = data.orders.map(item => {
                                const formattedDate = new Date(item.created_at).toLocaleDateString('ru-RU', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                });
                                return {
                                    ...item,
                                    images: item.images.split(','),
                                    created_at: formattedDate
                                };
                            });
                            setStore(storeWithModifications);
                        } else {
                            alert(data.error);
                        }
                    } else {
                        throw new Error(data.message || 'Failed to update order status');
                    }
                } catch (error) {
                    alert(`Error: ${error.message}`);
                }
            }
        } else {
            console.log('Status change cancelled.');
        }
    };
    
    
    
    useEffect(() => {
        const fetchOrders = async () => {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const user = JSON.parse(storedUser);
                try {
                    const response = await fetch(`https://adm.trevatour.ru/api/app/getallorders.php?login=${user.login}&hash=${user.hash}`);

                    const data = await response.json();

                    if (response.ok) {
                        if(data.orders){
                            const storeWithModifications = data.orders.map(item => {
                                const formattedDate = new Date(item.created_at).toLocaleDateString('ru-RU', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                });
                                return {
                                    ...item,
                                    images: item.images.split(','),
                                    created_at: formattedDate
                                };
                            });
                            setStore(storeWithModifications);
                        }else {
                            alert(data.error)
                        }
                    } else {
                        throw new Error(data.message || 'Login failed');
                    }
                } catch (error) {
                    alert(`Error: ${error.message}`);
                }
            }
        };

        fetchOrders();
    }, []);


    return (
        <div>
            <div style={{ padding: '20px', flex: 1, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <h1 style={{marginTop: 50, display: 'flex', gap: '20px'}}>
                    Заказы ({orders.length})
                </h1>
                {orders.map((item, index) => (
                    <div style={{width: '100%', display: 'flex', gap: '10px'}} key={index}>
                        <ImageSlider images={item.images}/>
                        <div>
                            <h3>{item.title}</h3>
                            <p><b>id: </b> {item.order_id}</p>
                            <p><b>Статус: </b> {item.status}</p>
                            <p><b>Количество: </b> {item.quantity}</p>
                            <p><b>Единица: </b> {item.point}</p>
                            <p><b>Цена: </b> {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
                                item?.price,
                            )}</p>
                            <p><b>Общая цена: </b>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(item.quantity * item.price)}</p>
                            <div style={{width: '100%', display: 'flex', gap: 10}}>
                                <img style={{width: '50px', height: '50px', borderRadius: '25px'}} src={item.user_image}/>
                                <p style={{fontWeight: '600'}}>{item.user_full_name} {item.user_id}</p>
                            </div>
                            <p>📅 {item.created_at}</p>
                            <p>{item.description}</p>
                            <a onClick={() => handleChange(item.order_id)} style={{color: 'black', textDecorationLine: 'underline'}}>Изменить Статус</a>
                        </div>
                    </div>
                    
                ))}
            </div>
        </div>
    );
};

export default Orders;
