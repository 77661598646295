import React, { useState, useEffect, Fragment } from 'react';

const Trips = () => {
    const [trips, setTrips] = useState([]);

    const handleDelete = async(id) => {
        const confirmDelete = window.confirm('Вы действительно хотите удалить?');
        if (confirmDelete) {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const user = JSON.parse(storedUser);
                try {
                    const response = await fetch(`https://adm.trevatour.ru/api/website/deletetrip.php?login=${user.login}&hash=${user.hash}&id=${id}`);
                    const data = await response.json();
        
                    if (response.ok) {
                        if(data.trips){
                            const tripsWithModifications = data.trips.map(item => {
                                const formattedDate = new Date(item.created_at).toLocaleDateString('ru-RU', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                });
                                return {
                                    ...item,
                                    created_at: formattedDate
                                };
                            });
                            setTrips(tripsWithModifications);
                        }else {
                            alert(data.error)
                        }
                    } else {
                        throw new Error(data.message || 'Login failed');
                    }
                } catch (error) {
                    alert(`Error: ${error.message}`);
                }
            }
        } else {
            console.log('Delete cancelled.');
        }
    };
    
    useEffect(() => {
        const fetchPlaces = async () => {
            try {
                const response = await fetch(`https://adm.trevatour.ru/api/website/gettrips.php`);

                const data = await response.json();

                if (response.ok) {
                    if(!data.message){
                        const tripsWithModifications = data.map(item => {
                            const formattedStartDate = new Date(item.start_date).toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });
                            const formattedEndDate = new Date(item.end_date).toLocaleDateString('ru-RU', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            });
                            return {
                                ...item,
                                start_date: formattedStartDate,
                                end_date: formattedEndDate
                            };
                        });
                        setTrips(tripsWithModifications);
                    }else {
                        alert(data.error)
                    }
                } else {
                    throw new Error(data.message || 'Login failed');
                }
            } catch (error) {
                alert(`Error: ${error.message}`);
            }
        };

        fetchPlaces();
    }, []);


    return (
        <div>
            <div style={{ padding: '20px', flex: 1, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <h1 style={{marginTop: 50, display: 'flex', gap: '20px'}}>
                    Поездки
                    <a href={`/website/trips/add`} style={{padding: 0, margin: 0, textDecoration: 'none'}}>
                    <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#4A6118"/>
                        <path d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z" fill="white"/>
                    </svg>
                    </a>
                </h1>
                {trips.map((post, index) => (
                    <>
                    <div style={{width: '100%', display: 'flex', gap: '10px'}} key={index}>
                        <img src={post.image_url} style={{height: '400px', width: '400px', borderRadius: 24}}/>
                        <div>
                            <h3>{post.title}</h3>
                            <h4>📅{post.start_date} → 📅{post.end_date}</h4>
                            <p><b>Макс. количество: </b>{post.max_count}</p>
                            <p><b>Kоличество: </b>{post.count}</p>
                            <p><b>Тип тура: </b>{post.type}</p>
                            <p><b>Сложность: </b>{post.difficulty}</p>
                            <p><b>Цена: </b> {new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(
                                post?.price,
                            )}</p>
                            <p><b>Команда: </b>{post.team.split('#').join(', ')}</p>
                            <p><b>Ссылка: </b>{post.link}</p>
                            <p style={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 3,
                                lineHeight: '1.5em',
                                maxHeight: '4.5em'
                            }}>
                                {post.short_description.split('\n').map((paragraph, index) => (
                                    <Fragment key={index}>
                                        <div dangerouslySetInnerHTML={{__html: paragraph}}></div>
                                    </Fragment>
                                ))}
                            </p>
                            <p style={{
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: 3,
                                lineHeight: '1.5em',
                                maxHeight: '4.5em'
                            }}>
                                {post.description.split('\n').map((paragraph, index) => (
                                    <Fragment key={index}>
                                        <div dangerouslySetInnerHTML={{__html: paragraph}}></div>
                                    </Fragment>
                                ))}
                            </p>
                            <a onClick={() => handleDelete(post.id)} style={{color: 'darkred', cursor: 'pointer', textDecorationLine: 'underline'}}>Удалить</a>
                            <a href={`/website/trips/${post.id}/change`} style={{marginLeft: '20px', color: 'black'}}>Изменить</a>
                        </div>
                    </div>
                    <div style={{width: 'calc(100vw - 80px)', display: 'flex', gap: 20, overflowX: 'scroll', scrollbarWidth: 'thin', padding: '20px'}}>
                    {
                        post?.schelude.split('#').map((description, index) => {
                            return(
                            <div style={{width: '400px', maxWidth: '100%', backgroundColor: 'white', alignItems: 'center', display: 'flex', flexDirection: 'column', boxhadow: "0px 3px 9px 7px rgba(0,0,0,0.19)", WebkitBoxShadow: "0px 3px 9px 7px rgba(0,0,0,0.19)"}}>
                                <p style={{color: 'black', textAlign: 'left', maxWidth: '90%', width: '400px', margin: '10px 0px', fontSize: '40px', fontFamily: 'Nevduplenysh-Regular',}}>{index + 1} ДЕНЬ</p>
                                <p style={{color: 'gray', textAlign: 'left', width: '400px', maxWidth: '90%', marginBottom: '10px'}}>{description}</p>
                            </div>
                            )
                        })
                    }
                    </div>
                    </>
                ))}
            </div>
        </div>
    );
};

export default Trips;
