    import React, { useState } from "react";
    import './Login.css';
    
    function Login({setIsAuthenticated}) {
        const [login, setLogin] = useState('');
        const [password, setPassword] = useState('');
    
        const handleSubmit = async (event) => {
            event.preventDefault();
            try {
                const response = await fetch(`https://adm.trevatour.ru/api/app/adminsignin.php?login=${login}&password=${password}`);
    
                const data = await response.json();
    
                if (response.ok) {
                    if(data.user){
                        setIsAuthenticated(true)
                        localStorage.setItem('user', JSON.stringify(data.user));
                    }else {
                        alert(data.error)
                        localStorage.clear()
                    }
                } else {
                    localStorage.clear()
                    throw new Error(data.message || 'Login failed');
                }
            } catch (error) {
                localStorage.clear()
                alert(`Error: ${error.message}`);
            }
        };
    
        return (
            <div className="login-container">
                <form className="login-form" onSubmit={handleSubmit}>
                    <h3>Вход в систему</h3>
                    <input
                        type="text"
                        placeholder="Логин"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Войти</button>
                </form>
            </div>
        );
    }
    
    export default Login;
    