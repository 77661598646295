import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ChangePlace = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [images, setImages] = useState([]);
  const { category, id } = useParams();
  const navigate = useNavigate();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleLatitudeChange = (e) => {
    setLatitude(e.target.value);
  };

  const handleLongitudeChange = (e) => {
    setLongitude(e.target.value);
  };

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...selectedFiles]);
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const storedUser = localStorage.getItem('user');
    if (!storedUser) {
      alert('Пользователь не найден');
      return;
    }

    if (!title || !description || !latitude || !longitude || images.length === 0) {
      alert('Заполните все поля');
      return;
    }

    const user = JSON.parse(storedUser);
    const formData = new FormData();
    formData.append('login', user.login);
    formData.append('hash', user.hash);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);
    formData.append('category', category);
    formData.append('id', id);

    if (typeof images[0] === 'string') {
      formData.append('images', images.join(','));
    } else {
      images.forEach((image) => {
        formData.append('images[]', image);
      });
    }

    try {
      const response = await fetch('https://adm.trevatour.ru/api/app/changeplace.php', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        navigate(`/categories`);
      } else {
        alert(data.error || 'Не удалось изменить место');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Произошла ошибка при изменении места');
    }
  };

  useEffect(() => {
    const fetchPlace = async () => {
      try {
        const response = await fetch(`https://adm.trevatour.ru/api/app/getplace.php?id=${id}`);
        const data = await response.json();

        if (response.ok) {
          if (data.place) {
            setTitle(data.place.title);
            setDescription(data.place.description);
            setLatitude(data.place.latitude);
            setLongitude(data.place.longitude);
            setImages(data.place.images.split(','));
          } else {
            alert(data.error);
          }
        } else {
          throw new Error(data.message || 'Failed to fetch place');
        }
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    };

    fetchPlace();
  }, [id]);

  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ margin: 0, paddingTop: '50px' }}>Изменить место</h1>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginTop: '10px' }}>
          Заголовок:
          <br />
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Описание:
          <br />
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            style={{ marginTop: '5px', width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Широта:
          <br />
          <input
            type="text"
            value={latitude}
            onChange={handleLatitudeChange}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Долгота:
          <br />
          <input
            type="text"
            value={longitude}
            onChange={handleLongitudeChange}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Фотографии:
          <br />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {images.map((image, index) => (
              <div key={index} style={{ position: 'relative', marginRight: '10px', marginBottom: '10px' }}>
                {typeof image === 'string' ? (
                  <img
                    src={image}
                    alt={`preview-${index}`}
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '4px', marginRight: '5px' }}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`preview-${index}`}
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '4px', marginRight: '5px' }}
                  />
                )}
                <button
                  onClick={() => handleRemoveImage(index)}
                  type="button"
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '10px',
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
          <br />
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          />
        </label>
        <button
          type="submit"
          style={{
            marginTop: '20px',
            padding: '10px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#4A6118',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ChangePlace;
