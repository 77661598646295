import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddReview = () => {
  const [description, setDescription] = useState('');
  const navigate = useNavigate()

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        e.preventDefault();
        
        // Проверка, что все поля заполнены
        if (description.length === 0) {
            alert('Заполните все поля');
            return;
        }

        const user = JSON.parse(storedUser);

        const formData = new FormData();
        formData.append('login', user.login);
        formData.append('hash', user.hash);
        formData.append('description', description);

        try {
            const response = await fetch('https://adm.trevatour.ru/api/website/addreview.php', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to add news');
            }

            const data = await response.json();
            if (data.success) {
                navigate('/website/reviews');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    } else {
        alert('Пользователь не найден');
    }
  };


  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ margin: 0, paddingTop: '50px' }}>Добавить отзыв</h1>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginTop: '10px' }}>
          Описание:
          <br />
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <button
          type="submit"
          style={{
            marginTop: '20px',
            padding: '10px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#4A6118',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddReview;
