import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddCategory = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const navigate = useNavigate()

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        const user = JSON.parse(storedUser);

        // Check that all fields are filled
        if (title.length === 0 || image == null) {
            alert('Заполните все поля');
            return;
        }

        const formData = new FormData();
        formData.append('login', user.login);
        formData.append('hash', user.hash);
        formData.append('title', title);
        formData.append('image', image);
    
        try {
            const response = await fetch('https://adm.trevatour.ru/api/website/addcategory.php', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to add news');
            }

            const data = await response.json();
            if (data.success) {
                navigate('/categories');
            } else {
                alert(data.error || 'Не удалось добавить категорию');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Произошла ошибка при добавлении категории');
        }
    } else {
        alert('Пользователь не найден');
    }
};


  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ margin: 0, paddingTop: '50px' }}>Добавить Категорию</h1>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginTop: '10px' }}>
          Заголовок:
          <br />
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Фотография:
          <br />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {image != null && <div style={{ position: 'relative', marginRight: '10px', marginBottom: '10px' }}>
              <img
                src={URL.createObjectURL(image)}
                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '4px', marginRight: '5px' }}
              />
            </div>}
          </div>
        <br/>
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          />
        </label>
        <button
          type="submit"
          style={{
            marginTop: '20px',
            padding: '10px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#4A6118',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddCategory;
