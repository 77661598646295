import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddBooking = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [point, setPoint] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [images, setImages] = useState([]);

  const navigate = useNavigate()

  const handleImageChange = (e) => {
    let selectedFiles = Array.from(e.target.files);
    setImages(prevImages => [...prevImages, ...selectedFiles]);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  const handleSubmit = async (e) => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        e.preventDefault();
        
        // Проверка, что все поля заполнены
        if (title.length === 0 || description.length === 0 || latitude.length === 0 || longitude.length === 0 || category.length === 0 || email.length === 0 || website.length === 0 || phone.length === 0 || price.length === 0 || point.length === 0 || images.length === 0) {
            alert('Заполните все поля');
            return;
        }

        const user = JSON.parse(storedUser);

        const formData = new FormData();
        formData.append('login', user.login);
        formData.append('hash', user.hash);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('latitude', latitude);
        formData.append('longitude', longitude);
        formData.append('category', category);
        formData.append('price', price);
        formData.append('point', point);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('website', website);

        for (let i = 0; i < images.length; i++) {
            formData.append('images[]', images[i]);
        }

        try {
            const response = await fetch('https://adm.trevatour.ru/api/app/addbooking.php', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to add news');
            }

            const data = await response.json();
            if (data.success) {
                navigate('/bookings');
            } else {
                alert(data.error || 'Не удалось добавить место');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Произошла ошибка при добавлении места');
        }
    } else {
        alert('Пользователь не найден');
    }
  };


  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ margin: 0, paddingTop: '50px' }}>Добавить ночлег</h1>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginTop: '10px' }}>
          Заголовок:
          <br />
          <input
            type="text"
            value={title}
            onChange={(e) => {
                setTitle(e.target.value);
            }}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Описание:
          <br />
          <textarea
            value={description}
            onChange={(e) => {
                setDescription(e.target.value);
            }}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          latitude:
          <br />
          <textarea
            value={latitude}
            onChange={(e) => {
                setLatitude(e.target.value);
            }}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          longitude:
          <br />
          <textarea
            value={longitude}
            onChange={(e) => {
                setLongitude(e.target.value);
            }}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Категория:
          <br />
          <textarea
            value={category}
            onChange={(e) => {
                setCategory(e.target.value);
            }}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Цена:
          <br />
          <textarea
            value={price}
            onChange={(e) => {
                setPrice(e.target.value);
            }}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Единица:
          <br />
          <textarea
            value={point}
            onChange={(e) => {
                setPoint(e.target.value);
            }}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Телефон:
          <br />
          <textarea
            value={phone}
            onChange={(e) => {
                setPhone(e.target.value);
            }}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Эл. почта:
          <br />
          <textarea
            value={email}
            onChange={(e) => {
                setEmail(e.target.value);
            }}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Вебсайт:
          <br />
          <textarea
            value={website}
            onChange={(e) => {
                setWebsite(e.target.value);
            }}
            style={{ marginTop: '5px', width: '100%', padding: '10px 0px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </label>
        <label style={{ marginTop: '10px' }}>
          Фотографии:
          <br />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {images.map((image, index) => (
            <div key={index} style={{ position: 'relative', marginRight: '10px', marginBottom: '10px' }}>
              <img
                src={URL.createObjectURL(image)}
                alt={`preview-${index}`}
                style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '4px', marginRight: '5px' }}
              />
              <button
                onClick={() => handleRemoveImage(index)}
                type='button'
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '10px',
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                &times;
              </button>
            </div>
          ))}
        </div>
        <br/>
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          />
        </label>
        <button
          type="submit"
          style={{
            marginTop: '20px',
            padding: '10px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#4A6118',
            color: '#fff',
            cursor: 'pointer',
          }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddBooking;
